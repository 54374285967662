<template>
  <div>
    <iframe :src="frame" width="100%" height="1200px" frameborder="0"></iframe>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import { i18n } from '@/plugins/i18n'
import font from './font.json'
import fontBold from './fontBold.json'
import { formatPrice, sumdate, currentCurrency, sumdatetime } from '@/services/filters'
import 'jspdf-autotable'
import { api } from "@/services/api"
import fontKR from "@/components/prints/fontKR.json";
import fontBlodKR from "@/components/prints/fontBoldKR.json";

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(fontKR)
    const fontsBold = ref(fontBlodKR)
    const dataTableList = ref([])
    const fontTitle = ref(9)
    const fontBody = ref(8)
    const lastTable = ref(0)
    const shopData = ref({})
    const frame = ref('')

    onMounted(() => {
      shopData.value = JSON.parse(localStorage.getItem('shop'))
      api.get({
        path: '/printOrder',
        param: `?order_id_list=${ route.value.query.id }&lang=${ route.value.query.lang }&currency_id=${ localStorage.getItem('currencyId') }`,
      }).then(({ data }) => {
        savePDF(data)
      }).catch((err) => {
        console.log(err)
      })
    })


    const headers = [
      '상품명',
      '단가',
      '수량',
      '할인',
      '금액',
    ]

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: [80, 210],
        unit: 'mm',
      })

      doc.setProperties({
        title: `พิมพ์ใบเสร็จรับเงิน`,
      })
      doc.addFileToVFS('-23-normal.ttf', fonts.value.data)
      doc.addFont('-23-normal.ttf', '-23', 'normal')
      doc.addFileToVFS('-23-bold.ttf', fontsBold.value.data)
      doc.addFont('-23-bold.ttf', '-23', 'bold')
      doc.setFont('-23', 'bold')
      for (let i = 0; i < data.length; i++) {
        const alignLeft = 5
        const spacer = 4
        let total = 5
        doc.autoTable({
          theme: 'plain',
          styles: {
            font: '-23',
            fontStyle: 'bold',
            textColor: '#212121',
            fontSize: 6,
            halign: 'left',
          },
          showFoot: 'lastPage',
          headStyles: {
            // textColor: '#1E88E5',
            fontStyle: 'bold',
            fontSize: 6,
            halign: 'left',
          },

          startY: 52,
          margin: {
            top: 52,
            left: 5,
            right: 5,
            bottom: 80,
          },

          head: [headers],
          body: data[i].order_detail_arr,
          didDrawPage(datax) {
            doc.setTextColor('#000')
            doc.setFontSize(12)
            doc.text(`${ shopData.value.shop_name }`, 4, total, null, null, 'left')
            doc.setFontSize(fontBody.value)
            total += spacer
            doc.text(`사업자번호 ${ shopData.value.shop_tax_number }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`주소 ${ shopData.value.shop_province } ${ shopData.value.shop_district }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`${ shopData.value.shop_subdistrict } ${ shopData.value.shop_address } ${ shopData.value.shop_zipcode }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`전화번호 ${ shopData.value.shop_phone }`, 4, total)
            total += spacer
            doc.text(`판매일  ${ sumdatetime(data[i].CREATE) }`, 4, total)
            total += spacer
            doc.text(`판매원  ${ data[i].user_create }`, 4, total)
            total += spacer - 2
            doc.setLineWidth(0.2)
            doc.setDrawColor('#37474F')
            doc.line(4, total, 76, total)
            total += spacer
            doc.text(`주문자 : ${ data[i].customer_lname } 주문번호 : ${ data[i].order_code }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`받는분 : ${ data[i].order_transportation_name }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`전화번호 : ${ data[i].order_transportation_phone }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`주소 ${ data[i].order_transportation_province } ${ data[i].order_transportation_district }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`${ data[i].order_transportation_subdistrict } ${ data[i].order_transportation_address } ${ data[i].order_transportation_zipcode } `, 4, total, null, null, 'left')
            total += spacer
            doc.setLineWidth(0.2)
            doc.setDrawColor('#37474F')
            doc.line(4, total + 2, 76, total + 2)
            total += spacer + 2
            total = datax.cursor.y - 2
            doc.setDrawColor('#37474F')
            doc.line(4, total + 0.5, 76, total + 0.5)
            total += spacer
            doc.text(`택배배송`, 4, total, null, null, 'left')
            doc.text(` ${ data[i].order_transportation_price }`, 76, total, null, null, 'right')
            total += spacer
            doc.text(`수량`, 4, total, null, null, 'left')
            doc.text(` ${ data[i].order_transportation_amount }`, 76, total, null, null, 'right')
            total += spacer
            doc.text(`합계`, 4, total, null, null, 'left')
            doc.text(` ${ data[i].order_transportation_total }`, 76, total, null, null, 'right')
            // total += spacer
            // doc.text(`부가세10%`, 4, total, null, null, 'left')
            // doc.text(` ${ data[i].order_vat }`, 76, total, null, null, 'right')
            total += spacer - 3
            doc.setDrawColor('#37474F')
            doc.line(4, total + 1, 76, total + 1)
            total += spacer
            const arrs = [
              { name: '총 가격', value: String(formatPrice(data[i].order_before_discount)) },
              { name: '할인 (%)', value: String(formatPrice(data[i].order_discount)) },
              { name: '할인 후 총 가격', value: String(formatPrice(data[i].order_after_discount)) },
              {
                name: `큰 통 ${ String(parseInt(data[i].order_vat)) }%`,
                value: String(formatPrice(data[i].order_vat_value))
              },
              // {
              //   name: `화물 ${ formatPrice(data[i].order_transportation_price) }*${ data[i].order_transportation_amount }`,
              //   value: String(formatPrice(data[i].order_transportation_total))
              // },
              { name: '정가', value: String(formatPrice(data[i].sum_total_transportation)) }
            ]
            for (let x = 0; x < arrs.length; x++) {
              doc.text(arrs[x].name, 4, total, null, null, 'left')
              doc.text(arrs[x].value, 76, total, null, null, 'right')
              total += spacer
            }
            total += spacer - 7
            doc.setDrawColor('#37474F')
            doc.line(4, total + 0.5, 76, total + 0.5)
            total += spacer
            doc.text(`입금은행 : ${ data[i].bank_full_name || '' }, 수취인명 : ${ data[i].bank_number || '' }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`입금금액 : ${ data[i].order_payment_total ? formatPrice(data[i].order_payment_total) : '' }, 송금인 : ${ data[i].order_payment_slip_confirm || '' }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`거래일시 : ${ data[i].order_payment_date ? sumdatetime(data[i].order_payment_date) : '' }`, 4, total, null, null, 'left')
            total += spacer - 3
            doc.setDrawColor('#37474F')
            doc.line(4, total + 0.5, 76, total + 0.5)
            total += spacer
            doc.text(`배송자 : ${ data[i].transport_name || '' }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`배송일 : ${ sumdate(data[i].order_transportation_date) }`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`평균 배송기간 1일이내`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`배송기간은 주말/공휴일을 제외한 영업일 기준`, 4, total, null, null, 'left')
            total += spacer
            total += spacer
            doc.text(`택배조회 링크`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`배송 추적 링크 ${ data[i].tracking_time }น.`, 4, total, null, null, 'left')
            total += spacer
            doc.text(`${ data[i].link }`, 4, total, null, null, 'left')
          },
        })
        doc.addPage(1)
      }
      doc.deletePage(data.length + 1)
      frame.value = doc.output('datauristring', { filename: `RECEIPT.pdf` })
    }

    return { savePDF, frame }
  },
}
</script>
